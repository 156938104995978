<template>
    <div class="c-hostname-picker">
        <MultiSelect 
            v-if="!unique && !disabled"
            display="chip" 
            v-model="contextHostNames" 
            option-label="label"
            option-value="hostName"
            :disabled="disabled"
            :options="availableHostNames"
            :class="class"
            @change="handleValueChange" />
        <Dropdown 
            v-if="unique && !disabled"
            v-model="contextHostName" 
            :disabled="disabled"
                        option-label="label"
            option-value="hostName"
            :options="availableHostNames"
            :class="class"
            @change="handleValueChange" />
        <InputText v-if="unique && disabled" :disabled="disabled" v-model="contextHostName" placeholder="/">
        </InputText>
        <InputText v-if="!unique && disabled" :disabled="disabled" v-model="contextHostNamesJoined" placeholder="/">
        </InputText>
    </div>
</template>

<script lang="ts">
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';

import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useParams } from '@/composables/utils/useParams';
import { useProjectEndpoints } from '@/composables/useProjectEndpoints';
import { EndpointClass, EndpointType } from '~/api/contracts/models';

export default defineComponent({
    components: {
        MultiSelect,
        Dropdown
    },

    props: {
        hostNames: {
            required: false,
            type: Object as PropType<Array<string>>
        },
        disabled: {
            type: Boolean
        },
        unique: {
            type: Boolean
        },
        minStage: {
            type: Number,
            required: false,
            default: 0
        },
        class: {
            type: Object,
            required: false
        }
    },

    setup(props, context) {
        const { projectId } = useParams()

        const endpoints = useProjectEndpoints(projectId)

        const availableHostNames = computed(() => endpoints.value ? endpoints.value
            .filter(x => 
                (x.endpointType == EndpointType.GlobalEndpoint ||  x.endpointType == EndpointType.GlobalEndpointAlias || 
                x.endpointClass == EndpointClass.Frontend || x.endpointClass == EndpointClass.Frontendprivate) 
                && x.environment == "prod")
            .filter(filterStage).map(x => { return { label: x.label, hostName: x.hostName! } }) : [])

        const contextHostNames = ref(props.hostNames ? props.hostNames : [])
        const contextHostNamesJoined = computed(() => {
            if (contextHostNames.value && contextHostNames.value.length > 0) {
                return contextHostNames.value.join(', ')
            }

            return ''
        })

        const contextHostName = ref(props.hostNames ? props.hostNames[0] : '')

        const filterStage = (endpoint: any) => {
            return endpoint.stageValue >= props.minStage
        }

        watch (
            () => availableHostNames, 
            () => contextHostNames.value = (props.hostNames && props.hostNames.length > 0) ? 
            [...props.hostNames] : 
            [...availableHostNames.value.map(h => h.hostName)], { immediate: true, deep: true})
        

        const handleValueChange = () => {
            if (props.unique)
            {
                context.emit('hostNamesChanged', [contextHostName.value])
            }
            else 
            {
                if (contextHostNames.value.isEquivalent(availableHostNames.value)) {
                    context.emit('hostNamesChanged', [])
                } 
                else {
                    context.emit('hostNamesChanged', contextHostNames.value)
                }
            }
        }

        return {
            availableHostNames,
            contextHostNames,
            handleValueChange,
            contextHostName,
            filterStage,
            contextHostNamesJoined
        }
    }
})
</script>

<style lang="scss">
.p-multiselect-items-wrapper {
    max-height: 300px !important;
}

.p-dropdown-items-wrapper {
    max-height: 300px !important;
}
</style>